.form {
  display: flex;
  flex-direction: column;
}

.form > label {
  font-size: 2rem;
  font-weight: 600;
}

.form > input {
  margin-top: 40px;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: 1.5rem;
  font-weight: var(--weight-light);
  padding-bottom: 5px;
  padding-left: 2px;
}

.form-button {
  text-align: right;
  margin-top: px;
  margin-right: 10px;
}

.btn {
  position: relative;
  outline: none;
  border: none;
  color: white;
  background-color: var(--main-color);
  font-weight: var(--weight-default);
  text-align: center;
  width: 80px;
  height: 50px;
  border-radius: 30px;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.btn:active {
  background-color: var(--main-color-active);
}

.btn > img {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 70%;
  top: 50%;
  left: 50%;
}

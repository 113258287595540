.info {
  margin-bottom: 20px;
  text-align: center;
}

.info > p {
  font-weight: var(--weight-bold);
}

.btn-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding-bottom: 30px;
}

.btn-container > button,
.btn-container > a > button {
  background-color: var(--main-color);
  font-weight: var(--weight-light);
  font-size: var(--font-default);
  color: white;
  outline: none;
  border: none;
  width: 260px;
  height: 40px;
  border-radius: 20px;
  transition: background-color 0.2s ease;
}

.btn-container > button:active,
.btn-container > a > button:active {
  background-color: var(--main-color-active);
}

.close {
  position: absolute;
  bottom: 0;
}

.active {
  background-color: var(--main-color-active) !important;
}

.active:active {
  background-color: var(--main-color) !important;
}

@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.react-calendar {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0 5px 10px 5px;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
}

.react-calendar__navigation__label {
}

.react-calendar__month-view__weekdays__weekday > abbr {
  font-weight: var(--weight-bold);
}

.react-calendar__navigation__label > span {
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  color: var(--main-color);
  font-size: var(--font-default);
}

.react-calendar__navigation__arrow {
  background-color: var(--main-color);
}

.react-calendar__tile {
  padding: 0.5 rem, 0.5rem;
}

.react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active {
  background-color: var(--danger-color);
  border-radius: 35%;
  color: white;
  font-weight: var(--weight-bold);
}

abbr {
  text-decoration: none !important;
  font-size: var(--font-small);
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

button:disabled {
  background-color: inherit !important;
  color: inherit !important ;
}

/* Imports */

/* Variables */
:root {
  --main-color: rgb(57, 76, 254);
  --main-color-active: rgb(104, 124, 255);
  --danger-color: rgb(219, 53, 69);

  --weight-bold: 700;
  --weight-default: 400;
  --weight-light: 300;

  --font-default: 1.05rem;
  --font-small: 0.85rem;
  --font-big: 1.35rem;
}

/* Global */
html,
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-size: 1.05rem;
  font-size: var(--font-default);
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-weight: var(--weight-default);
}

.Layout_header__3BUr4 {
  padding-top: 10px;
  height: 60px;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.Layout_header__3BUr4 > img {
  height: 100%;
}

.Layout_container__3OZn7 {
  margin: 0 auto;
  width: 88%;
  max-width: 500px;
}

.InitialSetupScreen_form__1bt1I {
  display: flex;
  flex-direction: column;
}

.InitialSetupScreen_form__1bt1I > label {
  font-size: 2rem;
  font-weight: 600;
}

.InitialSetupScreen_form__1bt1I > input {
  margin-top: 40px;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  font-size: 1.5rem;
  font-weight: var(--weight-light);
  padding-bottom: 5px;
  padding-left: 2px;
}

.InitialSetupScreen_form-button__2TDZW {
  text-align: right;
  margin-top: px;
  margin-right: 10px;
}

.InitialSetupScreen_btn__3uKBq {
  position: relative;
  outline: none;
  border: none;
  color: white;
  background-color: var(--main-color);
  font-weight: var(--weight-default);
  text-align: center;
  width: 80px;
  height: 50px;
  border-radius: 30px;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.InitialSetupScreen_btn__3uKBq:active {
  background-color: var(--main-color-active);
}

.InitialSetupScreen_btn__3uKBq > img {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70%;
  top: 50%;
  left: 50%;
}

.Location_message__gtZ5g {
  text-align: center;
}

.Location_message__gtZ5g > p {
  text-align: center;
  padding: 30px 0;
}

.Location_message__gtZ5g > div > p {
  font-size: 4.5rem;
}

.Location_btn-container__35jKs button {
  background-color: var(--main-color);
  font-weight: var(--weight-light);
  font-size: var(--font-default);
  color: white;
  outline: none;
  border: none;
  width: 65%;
  height: 40px;
  border-radius: 20px;
  transition: background-color 0.2s ease;
  margin: 7px 0;
}

.Location_btn-container__35jKs button:active {
  background-color: var(--main-color-active);
}

.MainScreen_info__S2wOV {
  margin-bottom: 20px;
  text-align: center;
}

.MainScreen_info__S2wOV > p {
  font-weight: var(--weight-bold);
}

.MainScreen_btn-container__2-EG9 {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  padding-bottom: 30px;
}

.MainScreen_btn-container__2-EG9 > button,
.MainScreen_btn-container__2-EG9 > a > button {
  background-color: var(--main-color);
  font-weight: var(--weight-light);
  font-size: var(--font-default);
  color: white;
  outline: none;
  border: none;
  width: 260px;
  height: 40px;
  border-radius: 20px;
  transition: background-color 0.2s ease;
}

.MainScreen_btn-container__2-EG9 > button:active,
.MainScreen_btn-container__2-EG9 > a > button:active {
  background-color: var(--main-color-active);
}

.MainScreen_close__3BS4e {
  position: absolute;
  bottom: 0;
}

.MainScreen_active__1qr0- {
  background-color: var(--main-color-active) !important;
}

.MainScreen_active__1qr0-:active {
  background-color: var(--main-color) !important;
}

.Message_message__kVpdt {
  text-align: center;
}

.Message_message__kVpdt > p {
  text-align: center;
  padding: 30px 0;
}

.Message_message__kVpdt > div > p {
  font-size: 4.5rem;
}

.Message_btn__2dV3u {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--main-color);
  text-decoration: underline;
}

.Message_btn__2dV3u:active {
  color: var(--danger-color);
}


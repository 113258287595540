.header {
  padding-top: 10px;
  height: 60px;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.header > img {
  height: 100%;
}

.container {
  margin: 0 auto;
  width: 88%;
  max-width: 500px;
}

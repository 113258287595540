.react-calendar {
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 0 5px 10px 5px;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
}

.react-calendar__navigation__label {
}

.react-calendar__month-view__weekdays__weekday > abbr {
  font-weight: var(--weight-bold);
}

.react-calendar__navigation__label > span {
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  color: var(--main-color);
  font-size: var(--font-default);
}

.react-calendar__navigation__arrow {
  background-color: var(--main-color);
}

.react-calendar__tile {
  padding: 0.5 rem, 0.5rem;
}

.react-calendar__tile--now {
  background-color: transparent;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active {
  background-color: var(--danger-color);
  border-radius: 35%;
  color: white;
  font-weight: var(--weight-bold);
}

abbr {
  text-decoration: none !important;
  font-size: var(--font-small);
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

button:disabled {
  background-color: inherit !important;
  color: inherit !important ;
}
